.App {
  text-align: center;
  font-family: sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.login-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
}

.login-container {
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  transition: transform 0.3s ease; /* Add transition for hover effect */
}

.login-container:hover {
  transform: scale(1.02); /* Scale up slightly on hover */
}

.App-logo {
  height: 100px;
  margin-bottom: 1rem;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  transition: transform 0.3s ease; /* Add transition for hover effect */
}


.login-form:hover {
  transform: scale(1.02); /* Scale up slightly on hover */
}

.login-form input {
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  transition: transform 0.3s ease; /* Add transition for hover effect */
}

.login-form input:hover {
  transform: scale(1.02); /* Scale up slightly on hover */
}

.login-form button {
  background-color: #2196F3;
  color: white;
  border: none;
  padding: 0.8rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  width: 100%;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Add transition for hover effect */
}

.login-form button:hover {
  background-color: #1976d2;
  transform: scale(1.02); /* Scale up slightly on hover */
}

.error {
  color: red;
  margin-top: 1rem;
}

.success-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
}

.success-container {
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  transition: transform 0.3s ease; /* Add transition for hover effect */
}

.success-container:hover {
  transform: scale(1.02); /* Scale up slightly on hover */
}

.home-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
}

.home-container {
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  transition: transform 0.3s ease; /* Add transition for hover effect */
}

.home-container:hover {
  transform: scale(1.02); /* Scale up slightly on hover */
}

.logout-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 0.8rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  width: 100%;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.logout-button:hover {
  background-color: #d32f2f;
  transform: scale(1.02);
}

.login-form-container {
  display: flex;
  flex-direction: column;
}

.more-login-options {
  display: none;
  margin-top: 1rem;
}

.more-login-options.show {
  display: block;
  animation: fadeIn 0.3s ease-in-out; /* Add fade-in animation */
}

.show-more-options {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  width: fit-content;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.show-more-options:hover {
  background-color: #45a049;
  transform: scale(1.02);
}

.show-more-options svg {
  margin-left: 0.5rem;
  vertical-align: middle;
}

.forgot-password-button {
  background-color: #f44336;
  color: white;
  margin-top: 1rem;
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.forgot-password-button:hover {
  background-color: #d32f2f;
  transform: scale(1.02);
}

.install-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #2196F3;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.install-button:hover {
  background-color: #1976d2;
  transform: scale(1.02);
}

/* Style only the register button */
.more-login-options button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 0.8rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  width: 100%;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.more-login-options button:hover {
  background-color: #45a049;
  transform: scale(1.02);
}

.dark-mode .more-login-options button {
  background-color: #555;
  color: white;
}

.dark-mode .more-login-options button:hover {
  background-color: #444;
}

.dark-mode-toggle {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  padding: 0.7rem;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  width: 30px;
  height: 30px;
}

.dark-mode-toggle:hover {
  background-color: rgba(255, 255, 255, 0.7);
}

.dark-mode-toggle svg {
  font-size: 1.2rem;
}

.dark-mode {
  background-color: #333;
  color: white;
}

.dark-mode .login-container,
.dark-mode .success-container,
.dark-mode .home-container,
.dark-mode .login-screen,
.dark-mode .success-page,
.dark-mode .home-page {
  background-color: #444;
}

.dark-mode .login-form input,
.dark-mode .login-form button,
.dark-mode .register-button,
.dark-mode .phone-button,
.dark-mode .forgot-password-button,
.dark-mode .logout-button,
.dark-mode .show-more-options,
.dark-mode .install-button {
  background-color: #555;
  color: white;
}

.dark-mode .error {
  color: #ffcccc;
}

:not(.dark-mode) .login-form button,
:not(.dark-mode) .register-button,
:not(.dark-mode) .phone-button,
:not(.dark-mode) .forgot-password-button,
:not(.dark-mode) .logout-button,
:not(.dark-mode) .show-more-options,
:not(.dark-mode) .install-button {
  color: grey;
}

/* Style login buttons */
.phone-button, .show-more-options, .forgot-password-button, .logout-button, .install-button, .more-login-options button, .login-form button {
  padding: 1rem 2rem;
  font-size: 1rem;
}

.login-container > button {
  margin: 0.5rem 0;
  width: 100%;
  box-sizing: border-box;
}

/* Refined glassmorphism */
.login-container, .success-container, .home-container {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

/* Add fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
